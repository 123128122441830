import React from 'react';

const NDPro = () => {
    return (
      <div className='w-screen h-screen flex justify-center items-center'>
        This page is coming soon
      </div>
    )
}

export default NDPro