import React from 'react'

const Info = () => {
  return (
    <div className="about__info grid">
        <div className="about__box">
            <i class="uil uil-award about__icon"></i>
            <h3 className="about__title">Experience</h3>
            <span className="about__subtitle">&ge; 6 ปี</span>
        </div>

        <div className="about__box">
            <i class="uil uil-comment-dots about__icon"></i>
            <h3 className="about__title">TOEIC</h3>
            <span className="about__subtitle">650 คะแนน</span>
        </div>

        <div className="about__box">
            <i class="uil uil-graduation-cap about__icon"></i>
            <h3 className="about__title">GPAX</h3>
            <span className="about__subtitle">3.48</span>
        </div>
    </div>
  )
}

export default Info