import React from 'react';
import './footer.css';

const Footer = () => {
  return (
    <footer className="footer">
        <div className="footer__container container">
            <h1 className="footer__title">W@nder</h1>

            <span className="footer__copy">
                &#169; Crypticalcoder. All rigths reserved
            </span>
        </div>
    </footer>
  )
}

export default Footer