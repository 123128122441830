import React, { useState } from 'react'
import './service.css';

const Survices = () => {
    const [toggleState, setToggleState] = useState(0);
    const toggleTab = (index) => {
        setToggleState(index);
    }

    return (
        <section className="survices section" id="survices">
            <h2 className="section__title">Benefits</h2>
            <span className="section__subtitle">ทักษะเหล่านี้นำมาใช้ประโยชน์อะไรได้บ้าง?</span>

            <div className="survices__container container grid">
                <div className="survices__content">
                    <div>
                        <i className="uil uil-restaurant survices__icon"></i>
                        <h3 className="survices__title">ด้านกำหนดอาหาร <br/> และวิจัย</h3>
                    </div>

                    <span className="survices__button" onClick={() => toggleTab(1)}>เพิ่มเติม
                        <i className="uil uil-arrow-right survices__button-icon"></i>
                    </span>

                    <div className={toggleState === 1 ? "survices__modal active-modal" : "survices__modal"}>
                        <div className="survices__modal-content">
                            <i className="uil uil-times survices__modal-close" onClick={() => setToggleState(0)}></i>

                            <h3 className="survices__modal-title">ด้านกำหนดอาหารและวิจัย</h3>
                            <p className="survices__modal-description">Survice with more than 6 years of experience. Providing quality work to clients and companies.</p>

                            <ul className="survices__modal-survices">
                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">ให้ความรู้ด้านโภชนาการแก่บุคคลทั่วไปและผู้ป่วยเฉพาะโรค</p>
                                </li>

                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">ประเมินสภาวะโภชนาการของผู้ป่วยตามวอร์ดต่างๆ ภายในโรงพยาบาล</p>
                                </li>

                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">รวบรวม จัดเก็บ และวิเคราะห์ข้อมูลเพื่อแก้ปัญหาที่เกิดขึ้น</p>
                                </li>

                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">เขียนบทความเพื่อเผยแพร่ความรู้ด้านโภชนาการ</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="survices__content">
                    <div>
                        <i className="uil uil-pen survices__icon"></i>
                        <h3 className="survices__title">ด้านกราฟฟิกดีไซน์</h3>
                        <h3 className='hidden__text'>x</h3>
                    </div>

                    <span className="survices__button" onClick={() => toggleTab(2)}>เพิ่มเติม
                        <i className="uil uil-arrow-right survices__button-icon"></i>
                    </span>

                    <div className={toggleState === 2 ? "survices__modal active-modal" : "survices__modal"}>
                        <div className="survices__modal-content">
                            <i className="uil uil-times survices__modal-close" onClick={() => setToggleState(0)}></i>

                            <h3 className="survices__modal-title">ด้านกราฟฟิกดีไซน์</h3>
                            <p className="survices__modal-description">Survice with more than 6 years of experience. Providing quality work to clients and companies.</p>

                            <ul className="survices__modal-survices">
                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">ออกแบบและจัดทำ <u>โปสเตอร์</u> สื่อการสอนและประชาสัมพันธ์</p>
                                </li>

                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">ออกแบบและจัดทำ <u>แผ่นพับ</u> สื่อการสอนและประชาสัมพันธ์</p>
                                </li>

                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">ออกแบบและจัดทำ <u>หนังสือ</u> สื่อการสอนและประชาสัมพันธ์</p>
                                </li>

                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">ออกแบบและจัดทำ <u>รูปภาพดิจิตอล</u> สำหรับสื่อการสอนและประชาสัมพันธ์ออนไลน์</p>
                                </li>

                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">ออกแบบ User Interface &#40;UI&#41; เว็บไซต์และแอพพลิเคชั่นบนมือถือ</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="survices__content">
                    <div>
                        <i className="uil uil-arrow survices__icon"></i>
                        <h3 className="survices__title">ด้านโปรแกรมมิ่ง</h3>
                        <h3 className='hidden__text'>x</h3>
                    </div>

                    <span className="survices__button" onClick={() => toggleTab(3)}>เพิ่มเติม
                        <i className="uil uil-arrow-right survices__button-icon"></i>
                    </span>

                    <div className={toggleState === 3 ? "survices__modal active-modal" : "survices__modal"}>
                        <div className="survices__modal-content">
                            <i className="uil uil-times survices__modal-close" onClick={() => setToggleState(0)}></i>

                            <h3 className="survices__modal-title">ด้านโปรแกรมมิ่ง</h3>
                            <p className="survices__modal-description">Survice with more than 6 years of experience. Providing quality work to clients and companies.</p>

                            <ul className="survices__modal-survices">
                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">พัฒนาโปรแกรมเพื่อใช้เป็นเครื่องมือในการทำงาน เช่น การประเมินภาวะโภชนาการ</p>
                                </li>

                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">พัฒนาโปรแกรมเพื่อเผยแพร่ความรู้</p>
                                </li>

                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">พัฒนาโปรแกรมเพื่อรวบรวมและจัดเก็บข้อมูล</p>
                                </li>

                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">ออกแบบและพัฒนา LINE chatbot</p>
                                </li>

                                <li className="survices__modal-survice">
                                    <i className="uil uil-check-circle services__modal-icon"></i>
                                    <p className="services__modal-info">สร้างระบบแจ้งเตือนอัตโนมัติด้วย LINE และ Email เพื่อการประชาสัมพันธ์</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Survices