import React from 'react';
import Header from './Header/Header';
import Home from './Home/Home';
import About from './about/About';
import Skill from './skill/Skill';
import Service from './services/Services';
import Qualification from './qualification/Qualification';
import Testimonial from './testimonial/Testimonial';
import Contact from './contact/Contact';
import Footer from './footer/Footer';
import ScrollUp from './scrollup/ScrollUp';
import './portfolio.css';

const Portfolio = () => {
    return (
        <>
            <Header/>

            <main className='main'>
                <Home/>
                <About/>
                <Skill/>
                <Service/>
                <Qualification/>
                <Testimonial/>
                <Contact/>
            </main>

            <Footer/>
        </>
    )
}

export default Portfolio