import React from 'react';
import "./skill.css";
import Skill_Research from './Skill_Research';
import Skill_Graphic from './Skill_Graphic';
import Skill_Programing from './Skill_Programing';

const Skill = () => {
  return (
    <section className="skills section" id="skills">
        <h2 className="section__title">Skills</h2>
        <span className="section__subtitle">ความสามารถพิเศษที่หลากหลายเพื่อองค์กรของคุณ</span>

        <div className="skills__container container grid">
          <Skill_Research/>
          <Skill_Graphic/>
          <Skill_Programing/>
        </div>
    </section>
  )
}

export default Skill