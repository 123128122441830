import React, { useState } from 'react';
import './qualification.css';

const Qualification = () => {
    const [toggleState, setToggleState] = useState(1);
    const toggleTab = (index) => {
        setToggleState(index);
    }

    return (
        <section className="qualification section" id='experience'>
            <h2 className="section__title">Experience</h2>
            <span className="section__subtitle">ประสบการณ์ที่ผ่านมา</span>

            <div className="qualification__container container">
                <div className="qualification__tabs">
                    <div className="qualification__button qualification__active button--flex" onClick={() => toggleTab(1)}>
                        <i className="uil uil-graduation-cap qualification__icon"></i>
                        Education
                    </div>

                    <div className="qualification__button button--flex" onClick={() => toggleTab(2)}>
                        <i className="uil uil-briefcase-alt qualification__icon"></i>
                        Experience
                    </div>
                </div>

                <div className="qualification__sections">
                    <div className={toggleState === 1 ? "qualification__content qualification__content-active" : "qualification__content"}>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">ประถมศึกษา</h3>
                                <span className="qualification__subtitle">โรงเรียนอนุบาลวัดอุทยานนที ชลบุรี</span>

                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2000 - 2006
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">มัธยมศึกษา</h3>
                                <span className="qualification__subtitle">โรงเรียนชลราษฎรอํารุง ชลบุรี</span>

                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2006 - 2012
                                </div>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">ปริญญาตรี</h3>
                                <span className="qualification__subtitle">คณะสหเวชศาสตร์ <br/> มหาวิทยาลัยบูรพา ชลบุรี</span>

                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2012 - 2016
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">ปริญญาโท</h3>
                                <span className="qualification__subtitle">คณะสหเวชศาสตร์ <br/> จุฬาลงกรณ์มหาวิทยาลัย กรุงเทพฯ</span>

                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2017 - 2023
                                </div>
                            </div>
                        </div>

                        <div className="qualification__next">
                            <div className="qualification__next-box">
                                <h3 className="qualification__title next__title">What's Next?</h3>
                                <p className="next__subtitle">กำลังสนใจ...</p>

                                <div className="qualification__next-list">
                                    <i class='bx bx-badge-check'></i>

                                    <div>
                                        <h3 className="qualification__next-name">iOS platform developement</h3>
                                        <span className="qualification__next-description">ศึกษาการพัฒนาโปรแกรมบนอุปกรณ์ iOS</span>
                                    </div>
                                </div>

                                <div className="qualification__next-list">
                                    <i class='bx bx-badge-check'></i>

                                    <div>
                                        <h3 className="qualification__next-name">3D modeling</h3>
                                        <span className="qualification__next-description">ศึกษาการปั้นโมเดล 3 มิติ</span>
                                    </div>
                                </div>

                                <div className="qualification__next-list">
                                    <i class='bx bx-badge-check'></i>

                                    <div>
                                        <h3 className="qualification__next-name">Data science</h3>
                                        <span className="qualification__next-description">ศึกษาการออกแบบและวิเคราะห์ฐานข้อมูลขนาดใหญ่</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={toggleState === 2 ? "qualification__content qualification__content-active" : "qualification__content"}>
                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">ผู้ช่วยวิจัย</h3>
                                <span className="qualification__subtitle">คณะสหเวชศาสตร์ <br/> มหาวิทยาลัยบูรพา ชลบุรี</span>

                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2015 - 2016
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">ผู้ช่วยวิจัย</h3>
                                <span className="qualification__subtitle">ศูนย์โรคไต <br/> โรงพยาบาลศิริราช กทมฯ</span>

                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2017 - 2018
                                </div>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div>
                                <h3 className="qualification__title">นักกำหนดอาหาร</h3>
                                <span className="qualification__subtitle">- โรงพยาบาลภูมิพลฯ กทมฯ <br/> - โรงพยาบาลศูนย์การแพทย์ฯ นครนายก</span>

                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2018 - 2023
                                </div>
                            </div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>
                        </div>

                        <div className="qualification__data">
                            <div></div>

                            <div>
                                <span className="qualification__rounder"></span>
                                <span className="qualification__line"></span>
                            </div>

                            <div>
                                <h3 className="qualification__title">ผู้ช่วยวิจัย<br/>(สัญญาระยะสั้น)</h3>
                                <span className="qualification__subtitle">โครงการสมองเสื่อมอัลไซเมอร์ครบวงจรในคนไทย (AD)<br/>ประจำราชวิทยาลัยจุฬาภรณ์ กทมฯ</span>

                                <div className="qualification__calendar">
                                    <i className="uil uil-calendar-alt"></i>
                                    2023 - ปัจจุบัน
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Qualification