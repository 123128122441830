import React from 'react';
import './testimonial.css';
import { Data } from './Data';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

const Testimonial = () => {
  return (
    <section className="testimonial container section" id='testimonial'>
        <h2 className="section__title">My past projects</h2>
        <span className="section__subtitle">ผลงานที่ผ่านมา</span>

        <Swiper className="testimonial__container"
        loop={true}
        grabCursor={true}
        spaceBetween={24}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          576: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 48,
          },
        }}
        modules={[Pagination, Autoplay]}
        >
          { Data.map(( {id, image, title, description, attached_url} ) => {
              return (
                  <SwiperSlide className="testimonial__card" key={id}>
                    <img src={image} alt="" className="testimonial__img" />

                    <h3 className="testimonial__name">{title}</h3>
                    <p className="testimonial__description">{description}</p>

                    <a href={attached_url} target="_blank" className="testimonial__card-button">ดูตัวอย่างผลงาน
                        <i className="bx bx-right-arrow-alt testimonial__card-button-icon"></i>
                    </a>
                  </SwiperSlide>
              )
          }) }
        </Swiper>
    </section>
  )
}

export default Testimonial