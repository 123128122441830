import chula_logo from "../../../assets/portfolio/testimonial_chula_logo.png";
import chulaporn_logo from "../../../assets/portfolio/testimonial_chulaporn_logo.png";
import eve_project from "../../../assets/portfolio/testimonial_eve_project.png";
import CKD_book from "../../../assets/portfolio/testimonial_CKD_book.png";
import PowerPoint from "../../../assets/portfolio/testimonial_powerpoint.png";
import physiology from "../../../assets/portfolio/testimonial_physiology.png";
import NDPro from "../../../assets/portfolio/testimonial_NDPro.png";
import NDProLite from "../../../assets/portfolio/testimonial_NDProLite.png";
import DM_chat from "../../../assets/portfolio/testimonial_DM_chat.png";
import findRecord from "../../../assets/portfolio/testimonial_findRecord.png";
import TDA_logo from "../../../assets/portfolio/testimonial_TDA_logo.png";

export const Data = [
    {
        id: 1,
        image: NDProLite,
        title: "NDPro mini",
        description:
        "แอพ NDPro เวอร์ชั่นเว็บ การทำ Dietary recall เพื่อประเมินอาหารและความต้องการของผู้ป่วย โดยตัดบางฟังก์ชั่นออกเพื่อลดความซับซ้อน",
        attached_url: "https://script.google.com/macros/s/AKfycbznjqjG1YIXht1e6ouAtatQ_uyBVQ-0TmYk0hntQBW4pIg7oQFnfZu-MrbCkBsc1pLrLQ/exec",
    },
    {
        id: 2,
        image: chulaporn_logo,
        title: "Dietary Record Online (จุฬาภรณ์)",
        description: 
        "พัฒนาแพลตฟอร์มออนไลน์ให้กับโรงพยาบาลจุฬาภรณ์ สำหรับผู้ป่วยในการส่งภาพถ่ายมาเก็บในไฟล์ Excel สำหรับให้นักกำหนดอาหารนำไปประเมินอาหารในภายหลัง",
        attached_url: "https://script.google.com/macros/s/AKfycbwEehv3yGcF_OXBx2g1Vaue_HFBjRr6y5k05VR2ukJbcR8rUZvbOTwWWZuENqlvS5Dx/exec",
    },
    {
        id: 3,
        image: CKD_book,
        title: "Booklet for CKD",
        description:
        "จัดทำหนังสือความรู้ด้านโภขนาการสำหรับผู้ป่วยโรคไตระยะก่อนล้างไต",
        attached_url: "https://drive.google.com/drive/folders/15IpPa6Xy7S-SylCpQCsWwC2p5wlyJxjy?usp=sharing",
    },
    {
        id: 4,
        image: PowerPoint,
        title: "PowerPoint ของหน่วยงาน",
        description:
        "ออกแบบและจัดทำ PowerPoint ใหักับหน่วยงานภายในจุฬาฯ สำหรับเผยแพร่สู่สาธารณะ",
        attached_url: "https://1drv.ms/b/s!AjbyRt5ONqo4oWTIGrGO_d5p0RpU?e=d2hjuJ",
    },
    {
        id: 5,
        image: TDA_logo,
        title: "COVID-19 Nutritional Poster",
        description: 
        "ออกแบบโปสเตอร์เรื่องโภชนาการระหว่างการระบาดของเชื้อ COVID-19",
        attached_url: "https://drive.google.com/drive/folders/1YoEcu_zPZHRjHqWxeHglsMWiaqaUWyOU?usp=share_link",
    },
    {
        id: 6,
        image: eve_project,
        title: "Book Cover Design",
        description:
        "ออกแบบและวาดปกนิยายตามความต้องการของผู้ว่าจ้าง",
        attached_url: "https://drive.google.com/drive/folders/1ELLgIpgKFfxnw_53i1w8qCf3_UvehhuS?usp=sharing",
    },
    {
        id: 7,
        image: physiology,
        title: "Images of Physiological Pathway",
        description:
        "วาดภาพกลไก Physiology เพื่อใช้ในการเรียนการสอนตามที่อาจารย์ต้องการ",
        attached_url: "https://drive.google.com/drive/folders/1EyO3hppsOjaG5gzOLegG7XsoZtQqwHoF?usp=share_link",
    },
];