import React, { useState } from 'react';
import "./header.css";
import { Link } from 'react-router-dom';

const Header = () => {
    /* HIGHLIGHT HEADER ONCE CLICKED AND RESET WHEN SCROLL */
    const [activeNav, setActiveNav] = useState("#empty");

    return (
        <nav className="mx-auto flex items-stretch justify-end sm:justify-between border-b border-gray-300 p-6 lg:px-8" aria-label="Global">
           <a href="index.html" className="nav__logo">W@nder</a>

            <div className="hidden 2xl:flex lg:gap-x-12 items-center">
                <a href="#home" onClick={() => setActiveNav("#home")} className={activeNav === "#home" ? "nav__link active-link" : "nav__link"}>Home</a>
                <a href="#about" onClick={() => setActiveNav("#about")} className={activeNav === "#home" ? "nav__link active-link" : "nav__link"}>About</a>
                <a href="#skills" onClick={() => setActiveNav("#skills")} className={activeNav === "#home" ? "nav__link active-link" : "nav__link"}>Skills</a>
                <a href="#experience" onClick={() => setActiveNav("#experience")} className={activeNav === "#home" ? "nav__link active-link" : "nav__link"}>Experience</a>
                <a href="#testimonial" onClick={() => setActiveNav("#testimonial")} className={activeNav === "#home" ? "nav__link active-link" : "nav__link"}>Working</a>
                <a href="#contact" onClick={() => setActiveNav("#contact")} className={activeNav === "#home" ? "nav__link active-link" : "nav__link"}>Contact</a>
            </div>
        </nav>
    )
}

export default Header